import Img from 'gatsby-image';
import React, { useEffect, useState } from "react";
import {
    GlobalContext
} from '../../GlobalContext';
import {
    Container
} from '../../constants';
import {
    galleryText
} from '../../texts';
import { findFromImages } from '../../utils/functions';
import {
    GalleryContainer,
    GalleryFilter,
    GalleryFilters,
    GalleryHome,
    GalleryShow
} from './GalleryComponents';
const Gallery = ({data,location}) => {

    const { theme , setTheme } = React.useContext(GlobalContext)
    const [selected, setSelected] = useState('_')
    const [refresh,setRefresh] = useState(false)
    const [foundImages,setFoundImages] = useState([])
    
    useEffect(() => {
        if(location.state.filter) {
            setSelected(location.state.filter)
        }
    },[])

    // function findAllImages(filterKey){
    //     const projectsKeys = []
    //     for (let index = 0; index < projectsTexts.high.length; index++) {
    //         const project = array[index];
    //         const images = findFromImages(project.link,data,false)     

    //     }
    //     findFromImages()
    // }
    useEffect(() => {
        findImages()
    },[selected])

    function reselect(filter){
        setSelected(filter)
        findImages()
        // if(refresh) return
        // setRefresh(true)
        // setTimeout(() => {
        //     setRefresh(false)
        //     findImages()
        // },800) 
    }

    function findImages(){
        const images = findFromImages(selected,data,false)
        const array = []
        for (let index = 0; index < images.length; index++) {
            const element = images[index];
            array.push(element)
        }
        setFoundImages([...array])
    }

    return (
            <GalleryContainer id="gallery" theme={theme}>
                {/* <GalleryNav>
                    <Link to='/'>
                        <Logo />
                    </Link>
                </GalleryNav> */}
                <Container>
                    <GalleryHome>
                        <div>
                            <div>
                                <span />
                                <span />
                                <span />
                                <span />
                            </div>
                            <h1>
                                {galleryText.title}
                            </h1>
                        </div>
                        <p>
                            {galleryText.description}
                        </p>
                    </GalleryHome>
                    <GalleryFilters>
                        {
                            galleryText.filters.map(
                                (filter,index) => 
                                <GalleryFilter theme={theme} selected={selected === filter.key} onClick={() => setSelected(filter.key)} className='gallery__filter' key={`gallery_filter_${index}`}>
                                    <div>
                                        {filter.html}
                                    </div>
                                    <span />
                                </GalleryFilter>
                            )
                        }
                    </GalleryFilters>
                    <GalleryShow id="show" theme={theme} refresh={refresh}>
                            {
                                foundImages?.map((projectImg,index) => (
                                    <div key={`gallery_show_images_${index}`}>
                                        <a href={projectImg.src} target="_blank">
                                            <Img 
                                              imgStyle={{
                                                objectFit: "fill",
                                                objectPosition: "50% 50%",
                                            }} 
                                                fluid={projectImg}
                                            />
                                        </a>
                                    </div>
                                ))
                            }
                        {/* <div>
                            {
                                left?.map((projectImg,index) => (
                                    <Img 
                                            imgStyle={{
                                                objectFit: "contain",
                                                objectPosition: "50% 50%",
                                            }} 
                                            fluid={projectImg}
                                    />
                                ))
                            }
                        </div>
                        <div>
                            {
                                right?.map((projectImg,index) => (
                                    <Img 
                                        imgStyle={{
                                            objectFit: "contain",
                                            objectPosition: "50% 50%",
                                        }} 
                                        fluid={projectImg}
                                    />
                                ))
                            }
                        </div> */}
                    </GalleryShow>
                </Container>
            </GalleryContainer>
    )
}

export default Gallery