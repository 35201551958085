import React from 'react'
import Gallery from '../components/Gallery'
import { graphql } from 'gatsby'

const GalleryPage = ({data,location}) => (
    <Gallery data={data} location={location}/>
)

export const query = graphql`
    query GalleryQuery  {
        allFile(filter: {extension: {regex: "/(jpg)|(png)/"}}) {
                edges {
                    node {
                    id
                    name
                     childImageSharp {
                        fluid(maxWidth: 2000){
                        ...GatsbyImageSharpFluid
                        }
                    }
                    }
                }
              }
      }
`

export default GalleryPage