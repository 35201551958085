import styled from "styled-components"
import {
    colors
} from '../../constants'

export const GalleryContainer = styled.section`
    @media screen and (max-width:400px){
        padding-top: 150px !important;
        div,p{
            line-height: 6vmin;
        }
        p{
            display: flex;
            width: 60vw;
        }
    }
    padding-top: 200px;
    display: flex;
    flex-direction: column;
    width: 100%;
    color: ${({theme}) => colors[theme].font};
    background-color: ${({theme}) => colors[theme].bg};
    transition: color .2s ease-in, background-color .2s ease-in,border .2s ease-in;
    >div>div:first-child span{
        background-color: ${({theme}) => colors[theme].border};
        transition: background-color .2s ease-in;
    }
    svg{
        width: 50px;
        height: 50px;
        /* fill: ${({theme}) => colors[theme].border}; */
    }
    >div:last-child{
        /* border-color: ${({theme}) => colors[theme].font}; */
        transition: border .2s ease-in;
    }
 
`

export const GalleryNav =  styled.div`
    display: flex;
    align-items: center;
    padding: 20px 50px;
`

export const GalleryHome = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    flex-direction: column;
    >div{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    span{
        display: flex;
        width: 10px;
        height: 10px;
        margin: 4px;
        margin-right: 20px;
    }
`

export const GalleryLinks = styled.div`
    position: fixed;
    width:max-content;
    padding: 10px;
    display: flex;
    flex-direction: column;
`

export const GalleryFilters = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding-top: 60px;
    margin-bottom: 50px;
`

export const GalleryFilter = styled.div`
    position: relative;
    z-index:1;
    width: 100px;
    height: 80px;
    margin: 20px;
    cursor: pointer;
    line-height: 2.5vmin;
    >div{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        color: ${({theme}) => colors[theme].bg};
        background-color: ${({theme}) => colors[theme].font};
        transition: color .2s ease-in, background-color .2s ease-in,border .2s ease-in;
    }
    >span{
        position: absolute;
        height: 10px;
        width: 10px;
        top: calc(100% - 9px);
        left: -9px;
        z-index: 1;
        background-color: ${({theme,selected}) => selected ? colors[theme].border : colors[theme].bg};
        border: 4px solid ;
        border-color:${({theme}) => colors[theme].bg};
        transition: background-color .2s ease-in,border-color .2s ease-in;
    }
    :hover{
        >span{
            background-color: ${({theme}) => colors[theme].border};
        }
    }
`

export const GalleryShow = styled.section`
    -webkit-columns: 30em;
    -moz-columns: 30em;
    columns: 30em;
    overflow: hidden;
    border-top: 8px solid;
    padding-top: 100px;
    padding-bottom: 100px;
    margin: auto;
    width: 100%;
    @media screen and (max-width:1200px){
            width: 80vw;
            display: flex;
            flex-direction: column;
            >div>a,div>a>div{
                width: 100%;
            }
    }
    >div>a>div{
        width: 500px;
        height: max-content;
        margin: 20px auto;
        flex: 1 auto;
        padding: 10px;
        align-items: center;
        box-shadow:${({theme}) => `0px 0px 1px ${colors[theme].font}`};
    }
    @media screen and (max-width:1200px){
        >div>a>div{
        width: 100%;
        height: max-content;
        margin: 20px auto;
        flex: 1 auto;
        padding: 10px;
        align-items: center;
        box-shadow:${({theme}) => `0px 0px 1px ${colors[theme].font}`};
    }
    }
    >div{
        opacity: ${({refresh}) => refresh ? '0' : '1'};
        /* transform: ${({refresh}) => refresh ? 'translateX(-2000px)' : 'translateY(0px)'}; */
        :hover{
            transform: translateY(-4px);
        }
        transition: transform .20s linear,opacity .6s linear;

    }
   
`
